import React from 'react'
import { withSnackbar } from 'notistack'
import { Grid, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

import Page from './page'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  sections: {
    maxWidth: '1000px',
    marginLeft: theme.spacing(1),
  },
}))

function Home() {
  const classes = useStyles()

  function content() {
    return (
      <Grid container className={classes.container}>
        <Grid item className={classes.sections}>
          <Typography>
            <p>
              <br /> Last Updated: Jun 08 2021
            </p>
            <p>&nbsp;</p>
            <h2>Terms and Conditions</h2>
            <p>
              This website and any mobile application (collectively, this
              &ldquo;Site&rdquo;) is owned by Made to Uplift (&ldquo;We&rdquo;,
              &ldquo;Us&rdquo; or &ldquo;Made to Uplift&rdquo;). We are
              providing you with access to this Site and our online store
              (together, our &ldquo;Services&rdquo;) subject to the following
              terms and conditions. <br /> By browsing, accessing, using,
              registering for or purchasing merchandise on this Site or
              otherwise using our Services, you are agreeing to all of the
              following terms and conditions, including any policies referred to
              herein (collectively, these &ldquo;Terms&rdquo;). So, please read
              these Terms carefully. We reserve the right to change this Site
              and these Terms at any time. If you are unwilling to be bound by
              these Terms&sbquo; you should not browse, access&sbquo; use&sbquo;
              register for or purchase merchandise from the Site. <br /> You
              represent and warrant that you are at least 18 years old or
              visiting this Site under the supervision of a parent or guardian.
            </p>
            <p>
              <strong>Privacy Policy</strong>
            </p>
            <p>
              Our Privacy Policy, which also governs your visit to Our Site, can
              be found &nbsp;
              <a title="privacy policy" href="./privacy">
                here.
              </a>{' '}
              Please review our Privacy Policy for information on how We
              collect, use and share information about our users.
            </p>
            <p>
              <strong>Use of This Site</strong>
            </p>
            <p>
              Subject to your compliance with these Terms&sbquo; We grant you a
              limited&sbquo; non-exclusive&sbquo; non-transferable&sbquo;
              non-sublicensable license to access and make personal&sbquo;
              non-commercial use of this Site. This license grant does not
              include: (a) any resale or commercial use of this Site or content
              therein; (b) the collection and use of any product listings or
              descriptions; (c) making derivative uses of this Site and its
              contents; or (d) use of any data mining&sbquo; robots&sbquo; or
              similar data gathering and extraction methods on this Site. You
              may not use&sbquo; frame or utilize framing techniques to enclose
              any of Our trademark&sbquo; logo&sbquo; content or other
              proprietary information (including the images found at this
              Site&sbquo; the content of any text or the layout/design of any
              page or form contained on a page) without Our express written
              consent. Further&sbquo; you may not use any meta tags or any other
              &ldquo;hidden text&rdquo; utilizing Our name&sbquo;
              trademark&sbquo; or product name without Our express written
              consent. Any breach of these Terms shall result in the immediate
              revocation of the license granted in this paragraph without notice
              to you. <br /> You are granted a limited, revocable, and
              nonexclusive right to create a hyperlink to the homepage of this
              Site so long as the link does not portray Us or Our products or
              services in a false, misleading, derogatory, or otherwise
              offensive matter. This limited right may be revoked at any time.
              You may not use any of Our logos or other proprietary graphics or
              trademarks as part of the link without express written permission.
            </p>
            <p>
              <strong>Account</strong>
            </p>
            <p>
              In order to access some features of this Site&sbquo; you may be
              required to register and We may assign to you, or you may be
              required to select, a password and user name or account
              identification. If you register&sbquo; you agree to provide Us
              with accurate and complete registration information&sbquo; and to
              inform us immediately of any updates or other changes to such
              information. <br /> You are solely responsible for protecting the
              security and confidentiality of the password and identification
              assigned to you. You shall immediately notify Us of any
              unauthorized use of your password or identification or any other
              breach or threatened breach of this Site's security. Each time you
              use a password or identification, you will be deemed to be
              authorized to access and use the Site in a manner consistent with
              these Terms, and We have no obligation to investigate the
              authorization or source of any such access or use of this Site.
              YOU WILL BE SOLELY RESPONSIBLE FOR ALL ACCESS TO AND USE OF THIS
              SITE BY ANYONE USING THE PASSWORD AND IDENTIFICATION ORIGINALLY
              SELECTED BY, OR ASSIGNED TO, YOU WHETHER OR NOT SUCH ACCESS TO AND
              USE OF THIS SITE IS ACTUALLY AUTHORIZED BY YOU, INCLUDING WITHOUT
              LIMITATION, ALL COMMUNICATIONS AND TRANSMISSIONS AND ALL
              OBLIGATIONS (INCLUDING WITHOUT LIMITATION FINANCIAL OBLIGATIONS)
              INCURRED THROUGH SUCH ACCESS OR USE.
            </p>
            <p>
              <strong>Electronic Communication</strong>
            </p>
            <p>
              When you use this Site, or send emails to Us, you are
              communicating with Us electronically. You consent to receive
              communications from Us electronically. We will communicate with
              you by e-mail or by posting notices on this Site or through our
              other services. You agree that all agreements, notices,
              disclosures and other communication that We provide to you
              electronically satisfy any legal requirements that such
              communications be in writing.
            </p>
            <p>
              <strong>User Content</strong>
            </p>
            <p>
              This Site may include features and functionality
              (&ldquo;Interactive Features&rdquo;) that allows users to create,
              post, transmit or store any content, such as text, music, sound,
              photos, video, graphics or code on the Sites ("User Content").
              User Content is publicly-viewable and may include your profile
              information and any content you post pursuant to your profile, but
              it does not include your account information or information you
              submit in order to make a purchase. You agree that you are solely
              responsible for your User Content and for your use of Interactive
              Features, and that your use any Interactive Features at your own
              risk. <br /> By using any Interactive Areas, you agree not to
              post, upload to, transmit, distribute, store, create or otherwise
              publish or send through the Sites any of the following: <br />{' '}
              User Content that is unlawful, libelous, defamatory, obscene,
              pornographic, indecent, lewd, suggestive, harassing, threatening,
              abusive, inflammatory, fraudulent or otherwise objectionable;
            </p>
            <ul>
              <li>
                User Content that would constitute, encourage or provide
                instructions for a criminal offense, violate the rights of any
                party or that would otherwise create liability or violate any
                local, state, national or international law;'
              </li>
              <li>
                User Content that displays, describes or encourages usage of any
                product We sell in a manner that could be offensive,
                inappropriate or harmful to Us or any user or consumer;
              </li>
              <li>
                User Content that may impinge upon or violate the publicity,
                privacy or data protection rights of others, including pictures,
                videos, images or information about another individual where you
                have not obtained such individual's consent;
              </li>
              <li>
                User Content that makes false or misleading statements, claims
                or depictions about a person, company, product or service
              </li>
              <li>
                User Content that may infringe any patent, trademark, trade
                secret, copyright or other intellectual or proprietary right of
                any party;
              </li>
              <li>
                User Content that impersonates any person or entity or otherwise
                misrepresents your affiliation with a person or entity; and{' '}
                <br /> Viruses, malware of any kind, corrupted data or other
                harmful, disruptive or destructive files or code.
              </li>
            </ul>
            <p>
              <strong>Rights in User Content</strong>
            </p>
            <p>
              Except as otherwise provided in these Terms, on this Site or in a
              separate agreement with Us, We claim no ownership or control over
              any User Content. However, by submitting or posting User Content
              on this Site, you grant to Us a nonexclusive, royalty-free,
              worldwide, perpetual, irrevocable, transferable, and fully
              sublicensable right to use, reproduce, modify, adapt, publish,
              translate, create derivative works from, distribute, perform and
              display such User Content on this Site and on third-party sites
              and mobile applications and in all other media or formats, whether
              currently known or hereafter developed, for any purpose and
              without any compensation to you. You also grant users of this Site
              the right to access your User Content in connection with their use
              of this Site. <br /> By posting User Content to this Site, you
              represent and warrant that (a) such User Content is
              non-confidential; (b) you own and control all of the rights, title
              and interest in and to the User Content or you otherwise have all
              necessary rights to post and use such User Content to this Site
              and to grant to Us the rights that you grant in these Terms; (c)
              the User Content is accurate and not misleading or harmful in any
              manner; and (d) the User Content, and your use and posting thereof
              in connection with this Site, do not and will not violate these
              Terms or any other of Our applicable terms, guidelines or policies
              or any applicable law, rule or regulation.
            </p>
            <p>
              <strong>Feedback</strong>
            </p>
            <p>
              Separate and apart from User Content, you may have the ability to
              submit questions, comments suggestions, reviews, ideas, plans,
              designs, notes, proposals, drawings, original or creative
              materials and other information regarding this Site, Us and our
              products or services (collectively "Feedback"). You agree that
              Feedback is non-confidential and shall become Our sole property.
              We shall own exclusive rights, including all intellectual property
              rights, in and to such Feedback and shall be entitled to the
              unrestricted use and dissemination of the Feedback for any
              purpose, commercial or otherwise, without acknowledgment or
              compensation to you.
            </p>
            <p>
              <strong>Restrictions on Rights to Use</strong>
            </p>
            <p>
              You agree you shall not (and you agree not to allow any other
              individual or entity using your password and identification to):{' '}
              <br /> download&sbquo; modify&sbquo; reproduce&sbquo; adapt&sbquo;
              translate&sbquo; reverse engineer&sbquo; create derivative works
              based upon&sbquo; publicly display&sbquo; sell&sbquo; rent&sbquo;
              license&sbquo; or in any way commercially exploit any portion of
              this Site&sbquo; except and to the extent expressly permitted
              under these Terms; <br /> remove any copyright&sbquo; trademark or
              other proprietary rights notice contained in or on the Site;{' '}
              <br /> use any robot&sbquo; spider&sbquo; site search/retrieval
              application&sbquo; or other device to retrieve or index any
              portion of this Site; <br /> collect any information about other
              users (including usernames and/or email addresses) for any purpose
              other than to solicit and/or share reviews with other users;{' '}
              <br /> reformat or frame any portion of any Web pages that are
              part of this Site; <br /> create user accounts by automated means
              or under false or fraudulent pretenses; <br /> create or transmit
              to other users unsolicited electronic communications&sbquo; such
              as &ldquo;spam&sbquo;&rdquo; or otherwise interfere with other
              users&rsquo; enjoyment of the Site; <br /> submit to this Site any
              content that falsely states or implies that such content is
              sponsored or endorsed by us; <br /> transmit or upload to this
              Site any item containing or embodying any virus&sbquo; worm&sbquo;
              defect&sbquo; malware&sbquo; Trojan horse&sbquo; software bomb or
              other feature designed to damage or degrade in any manner the
              performance of this Site&sbquo; any other Web site&sbquo; or any
              computer or other device or system&sbquo; or the enjoyment of this
              Site by any user; <br /> use this Site to violate the security of
              or gain unauthorized access to any computer or computer network or
              other device or system (including unauthorized attempts to
              discover passwords or security encryption codes); <br /> submit to
              this Site any content that is unlawful or facilitates&sbquo;
              constitutes&sbquo; promotes or encourages illegal activity; or
              otherwise use the Site to transfer or store illegal
              material&sbquo; including any material deemed threatening or
              obscene; <br /> copy or store any User Content offered on this
              Site other than for your personal&sbquo; non-commercial use;{' '}
              <br /> take any action that imposes&sbquo; or may impose&sbquo; in
              our sole discretion&sbquo; an unreasonable or disproportionately
              large data or traffic load on this Site or the IT infrastructure
              used to operate and make this Site available; or <br /> use this
              Site and/ or any User Content&sbquo; intentionally or
              unintentionally&sbquo; to violate any applicable local&sbquo;
              state&sbquo; federal or international law. <br /> We have no
              obligation to monitor any user conduct on this Site, and We
              reserve the right and have absolute discretion to monitor any user
              conduct on this Site at any time and for any reason without
              notice.
            </p>
            <p>
              <strong>Ownership</strong>
            </p>
            <p>
              As between you and Us&sbquo; this Site&sbquo; including all
              photographs&sbquo; images&sbquo; text&sbquo; graphics&sbquo;
              icons&sbquo; audio clips&sbquo; software&sbquo; source code and
              other aspects thereof (excluding User Content)&sbquo; all
              improvements or modifications thereof&sbquo; all derivative works
              based thereon&sbquo; and the collection&sbquo; arrangement&sbquo;
              and assembly of this Site (collectively, the &ldquo;Site
              Content&rdquo;)&sbquo; including all copyrights&sbquo;
              trademarks&sbquo; and other intellectual property or proprietary
              rights in the foregoing&sbquo; are owned by Us or our licensors
              and protected by applicable copyright laws. <br /> The use of any
              of Our trademarks or service marks without our express written
              consent is strictly prohibited. You may not use our trademarks or
              service marks in connection with any product or service in any way
              that is likely to cause confusion. You may not use our trademarks
              or service marks in any manner that disparages or discredits us.
              You may not use any of our trademarks or service marks in meta
              tags without prior explicit consent. Nothing in these Terms shall
              be deemed to grant to you or any other user any license or right
              in or to any of Our patents&sbquo; copyrights&sbquo;
              trademarks&sbquo; trade secrets or other proprietary rights.
            </p>
            <p>
              <strong>Purchases on this Site</strong>
            </p>
            <p>
              You agree that all of your transactions with or through this Site
              may, at Our option, be conducted electronically from start to
              finish. If We decide to proceed non-electronically, those
              transactions will still be governed by the remainder of these
              Terms unless you enter into different terms provided by us. You
              are responsible to print or make an electronic a copy of these
              Terms and any other contract or disclosure that we are required to
              provide to you. <br /> The risk of loss and title for items
              purchased by you on this Site pass to you upon our delivery of the
              items to the carrier pursuant to a shipment contract. <br /> We
              charge sales tax for merchandise ordered on this Site based on the
              applicable state sales tax rate of the location to which the order
              is being shipped. <br /> When We ship to you or per your
              directions to another person, you agree to pay the shipping and
              any handling charges shown on this Site when your order is placed.
              We reserve the right to increase, decrease and add or eliminate
              charges from time to time and without prior notice, so you agree
              to check all charges before placing an order or signing up for a
              service. Any shipping or handling charges may or may not reflect
              actual costs. <br /> Only valid credit cards or other payment
              method acceptable to us may be used. By submitting your order, you
              represent and warrant that you are authorized to use the
              designated card or method and authorize us to charge your order
              (including taxes, shipping, handling and any other amounts
              described on the Sites) to that card or other method. If the card
              (or other method) cannot be verified, is invalid, or is not
              otherwise acceptable, your order may be suspended or cancelled
              automatically. <br /> We attempt to be as accurate as possible and
              eliminate errors on this Site; however, We do not warrant that any
              product, service, description, photograph, pricing or other
              information is accurate, complete, reliable, current or
              error-free. In the event of an error, whether on this Site, in an
              order confirmation, in processing an order, delivering a product
              or service or otherwise, We reserve the right to correct such
              error and revise your order accordingly if necessary (including
              charging the correct price) or to cancel the order and refund any
              amount charged. Your sole remedy in the event of such error is to
              cancel your order and obtain a refund. <br /> All items are
              subject to availability and We reserve the right to impose
              quantity limits on any order, to reject all or part of an order
              and to discontinue products or services without notice, even if
              you have already placed your order. All prices are subject to
              change without notice. We reserve the right to refuse or cancel
              any orders placed for products and/or services which the sale or
              use of such product and/or service in your state or jurisdiction
              is restricted or prohibited.
            </p>
            <p>
              <strong>Links</strong>
            </p>
            <p>
              This Site may contain links to other sites on the Internet that
              are owned and operated by third parties. You acknowledge that We
              are not responsible for the operation of or content located on or
              through any such site.
            </p>
            <p>
              <strong>Termination</strong>
            </p>
            <p>
              You may terminate the Terms at any time by closing your account,
              discontinuing your use of this Site and providing Us with a notice
              of termination. We reserve the right, without notice and in our
              sole discretion, to terminate your right to use this Site, or any
              portion of this Site, and to block or prevent your future access
              to and use of this Site or any portion of this Site.
            </p>
            <p>
              <strong>Indemnification</strong>
            </p>
            <p>
              To the fullest extent permitted by applicable law, you agree to
              defend, indemnify and hold harmless Us and our subsidiaries and
              affiliates, and our respective officers, directors, agents,
              partners, members, employees, independent contractors, service
              providers and consultants ("Our Related Parties"), from and
              against any claims, damages, costs, liabilities and expenses
              (collectively, "Claims") arising out of or related to (a) your
              access to and use or misuse of this Site; (b) any User Content you
              post, upload, use, distribute, store or otherwise transmit on or
              through this Site; (c) any Feedback that you provide; (d) your
              violation of these Terms; and (e) your violation of any rights of
              another. You agree to promptly notify Us of any third party
              Claims, cooperate with Us in defending such Claims and pay all
              fees, costs and expenses associated with defending such Claims
              (including but not limited to attorneys' fees). You further agree
              that the We shall have the right to control of the defense or
              settlement of any third party Claims.
            </p>
            <p>
              <strong>Disclaimers</strong>
            </p>
            <p>
              Except as expressly provided, this Site, including all Site
              Content, and services provided on or in connection with this Site
              are provided on an "AS IS" and "WITH ALL FAULTS" basis without
              representations, warranties or conditions of any kind, either
              express or implied. WE DISCLAIM ALL OTHER REPRESENTATIONS,
              WARRANTIES, CONDITIONS AND DUTIES, EXPRESS, IMPLIED OR STATUTORY,
              INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES, DUTIES OR
              CONDITIONS: (A) OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE OR USE, RESULTS, TITLE, AND NON-INFRINGEMENT; AND (B)
              CREATED BY TRADE USAGE, COURSE OF DEALING OR COURSE OF
              PERFORMANCE. We does not represent or warrant that this Site is
              accurate, complete, reliable, current or error-free. We do not
              represent or warrant that this Site or our servers are free of
              viruses or other harmful components.
            </p>
            <p>
              <strong>Exclusivity of Remedy; Limitation of Liability</strong>
            </p>
            <p>
              Your sole and exclusive remedy, and Our sole and exclusive
              liability, for any breach of warranty shall be your right to
              return the product, or receive a refund for the service under Our
              applicable returns and exchanges policies. IN NO EVENT SHALL THE
              WE OR OUR RELATED PARTIES, BE LIABLE FOR SPECIAL, INDIRECT,
              INCIDENTAL, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS OR
              LOSS OF BUSINESS, EVEN IF THEY HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES, NOR SHALL OUR AND OUR RELATED
              PARTIES&rsquo; AGGREGATE LIABILITY, WHETHER IN CONTRACT, WARRANTY,
              TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED),
              OR OTHER THEORY, ARISING OUT OF OR RELATING TO THESE TERMS OR THE
              PURCHASE OR USE OF ANY PRODUCTS OR SERVICES PURCHASED THROUGH THIS
              SITE EXCEED THE PURCHASE PRICE OF THE PRODUCT OR SERVICE. THE
              LIMITATIONS SET FORTH IN THIS PARAGRAPH WILL NOT LIMIT OR EXCLUDE
              OUR OR OUR RELATED PARTIES&rsquo; GROSS NEGLIGENCE, FRAUD,
              INTENTIONAL, WILLFUL, MALICIOUS OR RECKLESS MISCONDUCT.
            </p>
            <p>
              <strong>Remedies</strong>
            </p>
            <p>
              You agree that our remedy at law for any actual or threatened
              breach of these Terms would be inadequate and that we shall be
              entitled to specific performance or injunctive relief, or both, in
              addition to any damages that we may be legally entitled to
              recover, together with reasonable expenses of any form of dispute
              resolution, including, without limitation, attorneys' fees. <br />{' '}
              No right or remedy of ours shall be exclusive of any other,
              whether at law or in equity, including without limitation damages
              injunctive relief, attorneys' fees and expenses.
            </p>
            <p>
              <strong>Modifications to Site</strong>
            </p>
            <p>
              We reserve the right to modify or discontinue, temporarily or
              permanently, this Site or any features or portions thereof without
              prior notice.
            </p>
            <p>
              <strong>Severability</strong>
            </p>
            <p>
              If any these provisions shall be deemed invalid, void, or for any
              reason unenforceable, that condition shall be deemed several and
              shall not affect the validity and enforceability of any remaining
              provision.
            </p>
            <p>
              <strong>No Third-Party Beneficiaries</strong>
            </p>
            <p>
              These Terms are for the benefit of, and will be enforceable by,
              the parties only. These Terms are not intended to confer any right
              or benefit on any third party or to create any obligations or
              liability of a party to any such third party.
            </p>
            <p>
              <strong>Miscellaneous</strong>
            </p>
            <p>
              No agency&sbquo; partnership&sbquo; joint venture&sbquo; or
              employment relationship is created as a result of these
              Terms&sbquo; and you do not have any authority of any kind to bind
              Us in any respect whatsoever. We may provide you with
              notices&sbquo; including those regarding changes to these
              Terms&sbquo; by email&sbquo; regular mail&sbquo; or postings on
              this Site. These Terms, which shall be deemed accepted by you upon
              your use of the Site&sbquo; constitute the entire agreement among
              you and Us regarding use of this Site. Our failure to exercise or
              enforce any right or provision of these Terms shall not constitute
              a waiver of the enforcement of such right or provision. If any
              provision of these Terms is found to be unenforceable or
              invalid&sbquo; that provision shall be limited or eliminated to
              the minimum extent necessary so that these Terms shall otherwise
              remain in full force and effect and enforceable. These Terms are
              not assignable&sbquo; transferable or sublicensable by you&sbquo;
              except with our prior written consent. These Terms include and
              incorporate by reference Our Privacy Policy, which can be found
              &nbsp;
              <a title="privacy policy" href="./privacy">
                here
              </a>
              , and any notices regarding the Site.
            </p>
            <p>
              <strong>Questions</strong>
            </p>
            <p>
              Questions regarding these Terms, Our Privacy Policy, or other
              policy related material can be directed to our support staff by
              emailing us at: info@madetouplift.com.
            </p>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return <Page name="Terms" content={content()} />
}

export default withSnackbar(Home)
